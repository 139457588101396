exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-electrics-js": () => import("./../../../src/pages/electrics.js" /* webpackChunkName: "component---src-pages-electrics-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-refund-and-returns-policy-js": () => import("./../../../src/pages/refund-and-returns-policy.js" /* webpackChunkName: "component---src-pages-refund-and-returns-policy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tips-js": () => import("./../../../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-templates-lecture-js": () => import("./../../../src/templates/lecture.js" /* webpackChunkName: "component---src-templates-lecture-js" */)
}

